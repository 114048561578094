const m = require("mithril");
import AppState from "../AppState";
import Refresher from '../utils/Refresher';
import {toHHMMSS, toMMSS} from '../utils/DateUtils'

class AthleteProfile{
    view(){
        let athlete = AppState.pageShareGet.athlete_data;
        let athleteKm = 0;
        if(athlete.cat.indexOf('42') >= 0){
            athleteKm = 42;
        }else if(athlete.cat.indexOf('21') >= 0){
            athleteKm = 21;
        }else if(athlete.cat.indexOf('10') >= 0){
            athleteKm = 10;
        }

        let flag_src = athlete.drzava
            ? 'https://78884ca60822a34fb0e6-082b8fd5551e97bc65e327988b444396.ssl.cf3.rackcdn.com/flags/40x30/'+athlete.drzava.toLowerCase()+'.png'
            : '';


        return <div class="athlete-profile">

            <div className="athlete-profile__category">{athlete.cat}</div>

            <div className="athlete-profile__sides">
                <div className="side side--image">
                    <img src={athlete.share_pic_url} alt=""/>
                </div>
                <div className="side side--main_info">
                    <table className="">
                        <tr>
                            <td width="1">Država</td>
                            <td><img src={flag_src} /></td>
                        </tr>
                        <tr>
                            <td width="1">Priimek</td>
                            <td>{athlete.priimek}</td>
                        </tr>
                        <tr>
                            <td width="1">Ime</td>
                            <td>{athlete.ime}</td>
                        </tr>
                        <tr>
                            <td width="1">Spol</td>
                            <td>{athlete.spol}</td>
                        </tr>
                    </table>

                </div>
                <div className="side side--times">
                    <table class="">
                        <tr class="tr-big">
                            <td>ŠTART</td>
                            <td colSpan="3">{athlete.start}</td>
                        </tr>
                        {[1,2,3,4,5,6,7,8].map(ii => {
                            if(ii*5 > athleteKm){
                                return;
                            }
                            let seconds = ii == 1
                                ? athlete.k1_ts
                                : athlete['k'+ii+'_ts'] - athlete['k'+(ii-1)+'_ts'];
                            let kmh = false;
                            let per_km = false;
                            if(seconds > 0){
                                kmh = (3600 / (seconds / 5)).toFixed(2);
                                per_km = Math.floor(seconds / 5)+"";
                            }
                            return <tr>
                                <td>{ii * 5}km</td>
                                <td>{toMMSS(seconds)}</td>
                                {kmh ? <td>{kmh} km/h</td> : <td></td>}
                                {per_km ? <td>{toMMSS(per_km)} / km</td> : <td></td>}
                            </tr>
                        })}
                        <tr class="tr-big">
                            <td>CILJ</td>
                            <td colSpan="3">{athlete.netto}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    }
}

function checkCodeChange(code){
    if(code == AppState.pageShareGet.code){
        return;
    }
    AppState.pageShareGet.code = code;
    let code_split = code.split('-');
    AppState.pageShareGet.stnr = code_split[0]*1;

    Refresher.getShareCodeInfo()

}

export default class{
    view(vnode){
        checkCodeChange(vnode.attrs.code)

        return <div class="page page--share">
            {AppState.pageShareGet.loading && <div class="label-loading">Profil se nalaga</div>}
            { AppState.pageShareGet.athlete_data != false && <AthleteProfile /> }
        </div>
    }
};