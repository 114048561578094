const m = require("mithril");
import AppState from "../AppState";

export default class{
    view(){
        return <div class="page page--home">
            {AppState.athletes.list.map(a => {
                return <div>{ a.priimek }</div>
            })}
        </div>
    }
};