const m = require('mithril');

/** @jsx m */

import PageHome from './PageHome/PageHome'
import PageShare from './PageShare/PageShare'
import PageLive from './PageLive/PageLive'
import PageFindPic from './PageFindPic/PageFindPic'
import Refresher from './utils/Refresher'
import AppState from './AppState.js'

/* *
* Instance id
* */

if(window.localStorage && window.localStorage.getItem('instance_id')){
    AppState.instance_id = window.localStorage.getItem('instance_id');
}else{
    AppState.instance_id = Math.floor(Math.random()*1000)+'-'+Date.now();
    if(window.localStorage){
        window.localStorage.setItem('instance_id', AppState.instance_id)
    }
}

/* API Url */
if(window.location.hostname == 'localhost'){
    AppState.apiUrl = 'http://localhost:40020/';
}

if(window.localStorage && window.localStorage.getItem("apiurl")){
    AppState.apiUrl = window.localStorage.getItem("apiurl");
}

if(window.location.href.indexOf('timing') > 0){
    AppState.isTiming = true
    AppState.stream = 'mapa'
}

/* *
*    ROUTES
* */

let routes = {
    "/": {
        onmatch: function (args) {
            AppState.currentPageRoot = 'home'
        },
        render: function () {
            if(AppState.isTiming){
                return <PageLive />
            }
            return [<PageHome />]
        }
    },
    "/live": {
        onmatch: function (args) {
            AppState.currentPageRoot = 'live'
        },
        render: function () {
            return [<PageLive />]
        }
    },
    "/find-pic": {
        onmatch: function (args) {
            AppState.currentPageRoot = 'find-pic'
        },
        render: function () {
            return [<PageFindPic />]
        }
    },
    "/share/:code": {
        onmatch: function (args) {
            AppState.currentPageRoot = 'share'
        },
        render: function (vnode) {
            return [<PageShare code={vnode.attrs.code} />]
        }
    },
};

if(document.location.href.indexOf('/sportal/ljubljanski-maraton') >= 0){
    // m.route.prefix('/sportal/ljubljanski-maraton');
    AppState.env = 'prod';
    AppState.apiUrl = 'https://video.siol.net/';
}else if(document.location.host == 'stream.siolmaraton.si'){
    AppState.env = 'prod';
    // AppState.apiUrl = 'https://video.siol.net/';
}else{
    // m.route.prefix('');

}
document.querySelector('.ljmaratonapp-container').innerHTML = 'Loading ...';
m.route(document.querySelector('.ljmaratonapp-container'), "/", routes);


/* *
*    PAGE LOAD THINGY
* */
function loadingFinished() {
    if (AppState.loadingReady.readyState && AppState.loadingReady.fonts){
        document.querySelector('.ljmaratonapp-container').classList.add('show')
        iframe_resize()
    }


    if(AppState.currentPageRoot == 'live'){
        Refresher.getGpsPositions();
        // Refresher.getStreamToken();
        Refresher.getAthletes();
    }
}

if (document.fonts) {
    document.fonts.ready.then(function () {
        AppState.loadingReady.fonts = true;
        loadingFinished()
    });

    document.fonts.onloadingdone = function (fontFaceSetEvent) {
        AppState.loadingReady.fonts = true;
        loadingFinished()
    };
} else {
    AppState.loadingReady.fonts = true;
    loadingFinished()
}

if (window.readyState === 'loading') {
    window.onreadystatechange = function () {
        if (window.document.readyState === "interactive" || window.document.readyState === "complete") {
            AppState.loadingReady.readyState = true;
            loadingFinished();
        }
    };
} else {
    AppState.loadingReady.readyState = true;
    loadingFinished();
}

/* *
*    CHECK FOR PASSIVE SUPPORT
* */
try {
    var opts = Object.defineProperty({}, 'passive', {
        get: function () {
            AppState.supportsPassive = true;
        }
    });
    window.addEventListener("test", null, opts);
} catch (e) {
}

/*
* DATA REFRESH
* */


setInterval(function(){
    let activeXHRs = 0;
    let prevActiveXHRs = AppState.loading_count * 1;
    // let toRemove =
    for(var ii = 0; ii < AppState.mXHRs.length; ii++){
        if(! AppState.mXHRs[ii]){
            continue;
        }
        if(AppState.mXHRs[ii].readyState == 4){
            delete AppState.mXHRs[ii];
        }else{
            activeXHRs++;
        }
    }
    AppState.mXHRs = AppState.mXHRs.filter(x => x)
    AppState.loading_count = activeXHRs;
    if(AppState.loading_count != prevActiveXHRs){
        m.redraw();
    }
}, 1000)

setInterval(function(){
    m.redraw();
}, 5 * 1000)

/* *
*  PAGE VISIBILITY
* */

document.addEventListener("visibilitychange", function () {
    AppState.visibilityState = document.visibilityState;
});

window.AppState = AppState

window.addEventListener("resize", function(){
    iframe_resize()
});

setInterval(function(){
    iframe_resize()
}, 500)

let prev_height = 0;
function iframe_resize(){
    var height = document.querySelector('.ljmaratonapp-container').clientHeight + 40;
    if (prev_height != height && parent.postMessage) {
        parent.postMessage(height, '*') //, "https://my-domain.com");
    }
    prev_height = height;
}

let activated_share_links = [];
window.addEventListener("message", function(msg){
    let data = msg.data;
    if(data.length && data[0] == '{'){
        data = JSON.parse(data);
    }
    if(data && data.url){
        if(data.url.indexOf('?share=')){
            let url_split = data.url.split('?share=');
            if(activated_share_links.indexOf(url_split[1]) >= 0){
                return;
            }
            m.route.set('/share/'+url_split[1])
        }
    }
}, false);

let checkJwPlayerInterval = setInterval(function(){
    // return;
    if(jwplayer) {
        clearInterval(checkJwPlayerInterval);
        Refresher.initAfterStreamToken()
        if(! document.querySelector('#jwplayer-inst')){
            return;
        }
        console.log('jwplayer init 1 - ' + AppState.loadJwFile)
        if(AppState.loadJwFile){

            AppState.jwObj = jwplayer('jwplayer-inst').setup({
                "file": AppState.loadJwFile,
                "aspectratio": '16:9',
                "width": '100%',
                autostart : 'viewable'
            });
        }else{

            AppState.jwObj = jwplayer('jwplayer-inst').setup({
                //"file": AppState.loadJwFile,
                "aspectratio": '16:9',
                "width": '100%',
                autostart : 'viewable'
            });
        }

    }
}, 300);

window.init_map = function(){
    console.log('initng map 2')
    if(! document.querySelector('#gmap')){
        return;
    }
    var uluru = {lat: 46.053009, lng: 14.508751};
    AppState.gmapsObj = new google.maps.Map(document.getElementById('gmap'), {
        zoom: 4,
        center: uluru,
        draggable: !("ontouchend" in document)
    });

    // var kmls = {
    //     '10' : require('../kml/10.kml.txt'),
    //     '21' : require('../kml/21.kml.txt'),
    //     '42' : require('../kml/42.kml.txt'),
    // }
    //
    // console.log(kmls)

    console.log('initing map')
    for(var _key in AppState.map_kml_layers){
        AppState.map_kml_layers[_key] = new google.maps.KmlLayer({
            // url: 'https://datastatsi-tmp-pub.s3.eu-central-1.amazonaws.com/tmp/ljm2019_' + _key + '.kml.txt'
            // url: 'https://s3.eu-central-1.amazonaws.com/ljmaraton-2017/ljm2019_'+_key+'.kml.txt'
            // url: 'https://yt-chunks-pullzone.b-cdn.net/kml/ljm2021/'+_key+'km.kml'
            url: 'https://smmx-pub.b-cdn.net/ljm/kml2023/' + _key + 'km.kml?v2'
            // url : kmls[_key]
        });
    }
    AppState.map_kml_layers['42'].setMap(AppState.gmapsObj);
    // AppState.map_kml_layers['21'].setMap(GMAP_OBJ);
    var _map_markers_offset = 0;
    for(var _key in AppState.map_markers){

        var image = {
            url: 'https://s3.eu-central-1.amazonaws.com/ljmaraton-2017/marker-' + _key + '.png',
            scaledSize: new google.maps.Size(50, 61),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(25, 61)
        };

        AppState.map_markers[_key] = new google.maps.Marker({
            position: { // set somewhere until positions are from db
                lat: 0.275195 + _map_markers_offset,
                lng: 0.511569 + _map_markers_offset
            },
            // map: GMAP_OBJ,
            icon: image,
            // icon: 'https://s3.eu-central-1.amazonaws.com/ljmaraton-2017/marker-' + _key + '.png'

            // icon: image // 'https://s3.eu-central-1.amazonaws.com/ljmaraton-2017/marker-' + _key + '.png',
        });
    }

    for(var marker_idx in AppState.map_markers){
        AppState.map_markers[marker_idx].setMap(null)
    }

    /*
    46.063714, 14.471598 - 21/42 km
    46.063715, 14.507894 - 10km trasa
    */
    AppState.map_markers['foto'].setMap(AppState.gmapsObj)
    AppState.map_markers['foto'].setPosition({
        lat: Number(AppState.fotoLocations["42"][0]),
        lng: Number(AppState.fotoLocations["42"][1])
    })

    /*let km = 1;
    AppState.map_markers['42m'].setMap(AppState.gmapsObj)
    setInterval(function(){
        console.log('...')
        AppState.map_markers['42m'].setPosition({
            lat : AppState.kmToLatLon['42'][km][0],
            lng : AppState.kmToLatLon['42'][km][1]
        })
        km++;
        if(km >= 42){
            km = 1;
        }
    }, 1500);*/

};

window.AppState = AppState;

if (!String.prototype.padStart) {
    String.prototype.padStart = function padStart(targetLength, padString) {
        targetLength = targetLength >> 0; //truncate if number, or convert non-number to 0;
        padString = String(typeof padString !== 'undefined' ? padString : ' ');
        if (this.length >= targetLength) {
            return String(this);
        } else {
            targetLength = targetLength - this.length;
            if (targetLength > padString.length) {
                padString += padString.repeat(targetLength / padString.length); //append to original to ensure we are longer than needed
            }
            return padString.slice(0, targetLength) + String(this);
        }
    };
}

document.addEventListener('keyup', function(e){
    /*if(AppState.currentPageRoot == 'find-pic' && e.key == 'ArrowLeft'){
        AppState.findPic.currentPhotoIndex--;
    }else if(ApS){

    }*/
})

// setTimeout(() => {
//     window.top.location.href = 'http://127.0.0.1/test/trka-iframe/?x=' + Math.floor(Math.random() * 100)
// }, 2000);

