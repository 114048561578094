import {buildHttpUrlParams} from "./helpers";

const m = require("mithril");
import AppState from "../AppState";


const Refresher = {
    getAthletes : function(){
        if(Date.now() - AppState.athletes.refresh_date < 200){
            return;
        }
        AppState.athletes.loading = true;
        setTimeout(function(){
            let req_data = {
                limit : 20,
                trasa : AppState.filterTrasa,
                k : AppState.filterK,
                gender : AppState.filterGender ? AppState.filterGender : 0,
                sort : AppState.sortOrder,
                q : AppState.filterAthlete
            };
            m.request({
                url : AppState.apiUrl+'marathon/athletes?' + buildHttpUrlParams(req_data),
                // data : req_data,
                method : 'get'
            }).then(function(data){
                AppState.athletes.list = data.feed;
                AppState.athletes.refresh_date = Date.now();
                AppState.athletes.loading = false;
            })
        }, 200)
        // AppState.athletes.list = ;
        // m.redraw()
    },

    updateMapMarkers : function(){
        AppState.map_kml_layers['10'].setMap(null);
        AppState.map_kml_layers['21'].setMap(null);
        AppState.map_kml_layers['42'].setMap(null);

        AppState.map_markers['10m'].setMap(null);
        AppState.map_markers['21m'].setMap(null);
        AppState.map_markers['42m'].setMap(null);
        AppState.map_markers['10z'].setMap(null);
        AppState.map_markers['21z'].setMap(null);
        AppState.map_markers['42z'].setMap(null);

        AppState.map_kml_layers[AppState.filterTrasa+'']
            .setMap(AppState.gmapsObj);

        // let mapMarkerId = '';
        // console.log(AppState.filterTrasa+AppState.filterGender)
        // AppState.map_markers[AppState.filterTrasa+AppState.filterGender].setMap(AppState.gmapsObj);

        if(AppState.filterTrasa == 42){
            AppState.map_markers['42m'].setMap(AppState.gmapsObj);
            AppState.map_markers['42z'].setMap(AppState.gmapsObj);
        }else if(AppState.filterTrasa == 21){
            AppState.map_markers['21m'].setMap(AppState.gmapsObj);
            AppState.map_markers['21z'].setMap(AppState.gmapsObj);
        }else if(AppState.filterTrasa == 10){
            AppState.map_markers['10m'].setMap(AppState.gmapsObj);
        }

        // if(AppState.filterGender == 'b'){
        //     AppState.map_markers[AppState.filterTrasa+'m'].setMap(AppState.gmapsObj);
        //     AppState.map_markers[AppState.filterTrasa+'z'].setMap(AppState.gmapsObj);
        // }else{
        //     let mapMarkerKey = AppState.filterTrasa + (AppState.filterGender == 'f' ? 'z' : AppState.filterGender);
        //     if(mapMarkerKey == '10z'){
        //         mapMarkerKey = '10m';
        //     }
        //     AppState.map_markers[mapMarkerKey].setMap(AppState.gmapsObj);
        // }
    },

    getAthleteRank : function(stnr){
        m.request({
            url : AppState.apiUrl+'marathon/get_athlete_rank?stnr=' + stnr,
            // data : req_data,
            method : 'get'
        }).then(function(data){
            AppState.athleteRank = data.rank
        })
    },

    getGpsPositions : function(){
        m.request({
            url : AppState.apiUrl+'marathon/get_gps_locations',
            method : 'get'
        }).then(function(data){
            AppState.gpsLocations = data.positions
            if(! AppState.gmapsObj){
                return
            }
            ['10m', '21m', '42m', '42z']
                .map(l => {
                    if(! data.positions[l]){
                        return;
                    }
                    AppState.map_markers[l]
                        .setPosition({
                            lat: Number(data.positions[l].lat),
                            lng: Number(data.positions[l].lon)
                        })
                })
        })
    },

    initAfterStreamToken : function(){
return;
        if(AppState.stream != 'mapa'){
            let stream_url = AppState
                .streamList[AppState.stream]
                .replace('_T_', AppState.streamToken);

            // AppState.loadJwFile = stream_url;

            /*
            if(AppState.jwObj){
                console.log('jwplayer init 2 - ' + stream_url)
                AppState.jwObj.load([{
                    "file": stream_url,
                    "image": ""
                }])
            }else{
                console.log('jwplayer init 3 - ' + stream_url)
                AppState.jwObj = jwplayer('jwplayer-inst').setup({
                    "file": stream_url,
                    "image": "",
                    "aspectratio": '16:9',
                    "width": '100%',
                    autostart : 'viewable'
                });
            }
            */
        }

    },

    getStreamToken : function(){

        this.initAfterStreamToken()

        /*let req_data = {
            user_id : AppState.instance_id
        };
        m.request({
            url : AppState.apiUrl+'marathon/get_stream_token',
            data : req_data,
            method : 'get'
        }).then(function(data){
            if(! data.token){
                alert('Prišlo je do napake. Prosimo osvežite stran');
                return;
            }
            AppState.streamToken = data.token;

        })*/
    },

    getAthletesForPictures : function(){
        AppState.findPic.athletesearch_status = 'loading'
        m.redraw()
        let req_data = {
            limit : 20,
            q : AppState.findPic.searchTerm,
            cat : '%',
            getint1 : 1
        };
        m.request({
            url : AppState.apiUrl+'marathon/athletes',
            data : req_data,
            method : 'get'
        }).then(function(data){
            AppState.findPic.athletes = data.feed;
            AppState.findPic.athletesearch_status = 'loaded'
        }).catch(function(data){
            AppState.findPic.athletesearch_status = 'loaded'
            alert('Prišlo je do napake pri iskanju slike, prosimo poskusite ponovno.')
        })
    },

    getAthletePictures : function(update){
        // marathon/find_pictures?stnr=1&time=11:46:45
        let req_data = {
            stnr : 0,
            time : AppState.findPic.targetPhotoTime,
            page : AppState.findPic.page*7
        };
        AppState.findPic.photosSearching = true;
        m.request({
            url : AppState.apiUrl+'marathon/find_pictures',
            data : req_data,
            method : 'get'
        }).then(function(data){
            AppState.findPic.photos = data.images;
            AppState.findPic.photosSearching = false;
            if(update && 'idx' in update){
                if(update.idx == 'last'){
                    AppState.findPic.currentPhotoIndex = AppState.findPic.photos.length-1
                }else{
                    AppState.findPic.currentPhotoIndex = update.idx
                }
            }
            if(update && 'pic_group' in update && AppState.findPic.photos.length > 2){
                // find next from beginning
                if(AppState.findPic.currentPhotoIndex == 0){
                    for(var ii = AppState.findPic.currentPhotoIndex; ii < AppState.findPic.photos.length; ii++){
                        if(AppState.findPic.photos[ii].pic_group == update.pic_group){
                            AppState.findPic.currentPhotoIndex = ii;
                            break;
                        }
                    }
                }else if(AppState.findPic.currentPhotoIndex > 0){
                    for(var ii = AppState.findPic.currentPhotoIndex; ii >= 0; ii--){
                        if(AppState.findPic.photos[ii].pic_group == update.pic_group){
                            AppState.findPic.currentPhotoIndex = ii;
                            break;
                        }
                    }
                }

            }
        })

    },

    createSharePic : function(){
        //create_share_pic
        let pic = AppState.findPic.photos[AppState.findPic.currentPhotoIndex];
        let req_data = {
            stnr : AppState.findPic.stnr,
            base_name : pic.filename_base,
            x : AppState.findPic.x,
            y : AppState.findPic.y,
            w : AppState.findPic.w,
            h : AppState.findPic.h
        };
        AppState.findPic.photoCreating = true;
        m.request({
            url : AppState.apiUrl+'marathon/create_share_pic',
            data : req_data,
            method : 'get'
        }).then(function(data){
            if('status' in data && data.status == 'ok'){
                AppState.findPic.photoCreatingMessage = 'Slika kreirana';
                AppState.findPic.photoCreatingSharecode = data.share_code;
                AppState.findPic.photoCreatingUrl = data.img_url;
                AppState.findPic.step = 3;
            }else{
                AppState.findPic.photoCreatingMessage = 'Napaka pri kreiranju slike. Poiskusite čez nekaj trenutkov.';
            }

            AppState.findPic.photoCreating = false;
            // console.log(update)
            // AppState.findPic.photos = data.images;
            // AppState.findPic.photosSearching = false;
            // if(update && 'idx' in update){
            //     AppState.findPic.currentPhotoIndex = update.idx
            // }
        }).catch(function(){
            AppState.findPic.photoCreatingMessage = 'Napaka pri kreiranju slike. Poiskusite čez nekaj trenutkov.';
            AppState.findPic.photoCreating = false;
        })
    },

    getShareCodeInfo : function(){
        if(Date.now() - AppState.athletes.refresh_date < 200){
            return;
        }
        AppState.pageShareGet.loading = true;
        let req_data = {
            limit : 20,
            q : AppState.pageShareGet.stnr,
            share_code : AppState.pageShareGet.code,
            cat : '%'
        };
        m.request({
            url : AppState.apiUrl+'marathon/athletes',
            data : req_data,
            method : 'get'
        }).then(function(data){
            if(! data.feed.length || data.feed[0].stnr != AppState.pageShareGet.stnr){
                alert('Napaka pri pridobivanju podatkov');
                return;
            }
            AppState.pageShareGet.loading = false;
            AppState.pageShareGet.athlete_data = data.feed[0];
            AppState.pageShareGet.athlete_data['share_pic_url'] = data.share_pic_url.url
        })
    },
}

export default Refresher;