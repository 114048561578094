import Refresher from "../utils/Refresher";
const m = require("mithril");
import AppState from "../AppState";
import Cropper from 'cropperjs'

class FindPicStepper{
    view(){
        return <div className="stepper">
            {[1, 2, 3].map(step => {
                let step_class = 'stepper__step';
                if(AppState.findPic.step >= step){
                    step_class += ' stepper__step--active';
                }
                return <div className={step_class} onclick={() => {
                        if(AppState.findPic.step < step) return;
                        AppState.findPic.step = step;
                    }}>Korak {step}</div>
            })}
        </div>
    }
}

function selectStnr(stnr, time){
    AppState.findPic.stnr = stnr;
    AppState.findPic.step = 2;
    AppState.findPic.targetPhotoTime = time;
}

function sumStartTimeAndK(start, k){
    let ret = [0,0,0];
    if(! start){
        return '/';
    }
    if(start.indexOf(':') === false){
        return '/';
    }
    start = start.split(':');
    k = k.split(':')
    ret[0] += (start[0]*1) + (k[0]*1);
    ret[1] += (start[1]*1) + (k[1]*1);
    ret[2] += (start[2]*1) + (k[2]*1);
    // alert(ret);
    if(ret[2] >= 60){
        ret[1]++; // ret[2]%60;
        ret[2] -= 60;
    }
    if(ret[1] >= 60){
        ret[0] += Math.floor(ret[1]/60);
        ret[1] -= 60;
    }
    return ret[0]+':'+
        ret[1].toString().padStart(2, '0')+':'+
        ret[2].toString().padStart(2, '0')
}

class FindPicStep1{
    view(){
        return <div class="step-content step-content--1">
            <h3>Poiščite se med tekmovalci</h3>
            <div className="input-container">
                <input type="text"
                       placeholder="Vnesite štartno številko ali pa priimek"
                       onkeyup={m.withAttr("value", AppState.findPic.setSearchTerm)} />
                {AppState.findPic.athletesearch_status == 'loading' && <div style="position: absolute; display: inline-block; padding-left: 5px; padding-top: 2px;">
                    <div class="loader"></div>
                </div>}

            </div>
            <table class="table table--stripped">
                <thead>
                    <tr>
                        <th width="130">Štartna številka</th>
                        <th>Priimek</th>
                        <th>Ime</th>
                        <th width="100">Foto Čas</th>
                    </tr>
                </thead>
                <tbody>
                    {AppState.findPic.athletes.map(a => {
                        // console.log(a, a.int1)
                        // let calcTime = sumStartTimeAndK(a.k1, '00:00:10'); // sumStartTimeAndK(a.start, a.k1);
                        let calcTime = '00:00:00';
                        if(a.start < "10:00:00"){
                            calcTime = sumStartTimeAndK(a.start, a.k11);
                        }else{
                            calcTime = sumStartTimeAndK(a.start, a.k3);
                        }
                        return <tr>
                            <td onclick={() => selectStnr(a.stnr, calcTime)}>{a.stnr}</td>
                            <td onclick={() => selectStnr(a.stnr, calcTime)}>{a.priimek}</td>
                            <td onclick={() => selectStnr(a.stnr, calcTime)}>{a.ime}</td>
                            <td onclick={() => selectStnr(a.stnr, calcTime)}>{calcTime}</td>
                        </tr>
                    })}
                </tbody>
            </table>
        </div>
    }
}

let cropper_instance = null;
let previous_img = '';

function initCropper(){
    let pic = AppState.findPic.photos[AppState.findPic.currentPhotoIndex];

    /*if(pic){
        if(cropper_instance){
            cropper_instance.destroy();
        }
        return;
    }*/

    if(! pic || pic.filename_base == previous_img){
        return;
    }

    // console.log(pic.filename_base, previous_img)

    if(cropper_instance){
        cropper_instance.destroy();
    }

    setTimeout(function(){
        const image = document.getElementById('photo-crop-orig');
        cropper_instance = new Cropper(image, {
            aspectRatio: 1/1,
            preview : '.photo-crop__preview-element',
            zoomable: false,
            zoomOnWheel : false,
            crop(e) {

                var image_height = document.querySelector('#photo-crop-orig').height;
                var image_width = document.querySelector('#photo-crop-orig').width;

                AppState.findPic.x = (e.detail.x/image_width).toFixed(4);
                AppState.findPic.y = (e.detail.y/image_height).toFixed(4);
                AppState.findPic.w = (e.detail.width/image_width).toFixed(4);
                AppState.findPic.h = (e.detail.height/image_height).toFixed(4);
            },
        });
    }, 50)


    previous_img = pic.filename_base;
}

function doAthletePic(){
    Refresher.createSharePic();
}

class FindPicStep2PhotoCrop{
    oninit(){
        this.src = '';
        this.changed = false;
        this.cropper = null;
    }
    view(){
        let pic = AppState.findPic.photos[AppState.findPic.currentPhotoIndex];
        if(! pic) return '';
        // let img_src = 'http://127.0.0.1/repo/planetslo/wings/src/public/processed/'+pic.filename_base+'_1200.jpg';
        // let img_src = 'https://s3.eu-central-1.amazonaws.com/ljmaraton-2018/ljmaraton-2018/pics/'+pic.filename_base+'_1200.jpg';
        let img_src = 'https://s3.eu-central-1.amazonaws.com/ljmaraton-2018/ljmaraton-2019/pics/'+pic.filename_base+'_1200.jpg';
        return <div className="photo-crop">
            <div class="photo-crop__original">
                <img src={img_src} alt="" id="photo-crop-orig" />
            </div>
            <div className="photo-crop__preview">
                <div className="photo-crop__preview-label">Predogled</div>
                <div className="photo-crop__preview-element"></div>
                {AppState.findPic.photoCreating && <div class="">
                    Slika se kreira

                    <div style="position: absolute; display: inline-block; padding-left: 5px; padding-top: 2px;">
                        <div class="loader"></div>
                    </div>

                </div>}
                {AppState.findPic.photoCreatingMessage}
                {! AppState.findPic.photoCreating && <div className="photo-crop__share-btn" onclick={() => {
                        doAthletePic();
                    }}>
                    Izdelaj sliko
                </div>}
            </div>
        </div>
    }
}

function findPreviousIndex(){
    let currentPicGroup = AppState.findPic.photos[AppState.findPic.currentPhotoIndex].pic_group
    let previousPicIndex = false
    for(var ii = AppState.findPic.currentPhotoIndex-1; ii >= 0; ii--){
        if(AppState.findPic.photos[ii].pic_group == currentPicGroup){
            previousPicIndex = ii;
            break;
        }
    }
    if(previousPicIndex === false){
        AppState.findPic.page--;
        Refresher.getAthletePictures({
            pic_group : currentPicGroup,
            idx : 'last'
        })
    }else{
        AppState.findPic.currentPhotoIndex = previousPicIndex
    }
}

function findNextIndex(){
    let currentPicGroup = AppState.findPic.photos[AppState.findPic.currentPhotoIndex].pic_group
    let nextPicIndex = false
    for(var ii = AppState.findPic.currentPhotoIndex+1; ii < AppState.findPic.photos.length; ii++){
        if(AppState.findPic.photos[ii].pic_group == currentPicGroup){
            nextPicIndex = ii;
            break;
        }
    }
    if(! nextPicIndex){
        AppState.findPic.page++;
        Refresher.getAthletePictures({
            pic_group : currentPicGroup,
            idx : 0
        })
    }else{
        AppState.findPic.currentPhotoIndex = nextPicIndex
    }
}

class FindPicStep2PhotoList{
    view(){
        return <div>

            {AppState.findPic.showNotification && <div onclick={() => {
                AppState.findPic.showNotification = false;
            }
            } style="margin-top: 10px; margin: 20px; padding: 20px; font-size: 18px; border: 1px solid #c3c3c3; text-align: center;">
                Za vas so bile najdene te slike. Izberite najboljšo. V naslednjem koraku boste imeli na voljo predogled. <br/>
                Z gumbona Naslednja in prejšnja slike se pomikate po slikah narejene iz ene kamere. Če želite gledati slike iz druge kamere izberite drugo sliko.
                <br/>
                <b>ZAPRI</b>
            </div>}


            <div className="photo-selector">
                <div className="btns">
                    <div className="btns__btn" onclick={() => {
                        AppState.findPic.page--;
                        Refresher.getAthletePictures({
                            idx : 3
                        })
                        initCropper();
                    }}>◄ Stran</div>
                    <div className="btns__btn" onclick={() => {
                        /*if(AppState.findPic.currentPhotoIndex == 0){
                            AppState.findPic.page--;
                            Refresher.getAthletePictures({
                                idx : 6
                            })
                        }else{
                            AppState.findPic.currentPhotoIndex--;
                        }*/
                        findPreviousIndex()
                        initCropper();
                    }}>◄ Slika</div>
                </div>
                <div className="pics">
                    {AppState.findPic.photos.map((p, idx) => {
                        let this_pic_idx = idx*1;
                        // let pic_url = 'http://127.0.0.1/repo/planetslo/wings/src/public/processed/'+p.filename_base+'_200.jpg';
                        let pic_url = 'https://s3.eu-central-1.amazonaws.com/ljmaraton-2018/ljmaraton-2018/pics/'+p.filename_base+'_200.jpg';
                        let div_class = 'pics__pic';
                        div_class += idx == AppState.findPic.currentPhotoIndex ? ' pics__pic--active' : '';
                        return <div class={div_class} onclick={() => {
                            AppState.findPic.currentPhotoIndex = this_pic_idx
                            initCropper();
                        }}>
                            {p.file_time}
                            <img src={pic_url} alt="" />
                        </div>
                    }) }
                </div>
                <div className="btns">
                    <div className="btns__btn" onclick={() => {
                            AppState.findPic.page++;
                            Refresher.getAthletePictures({
                                idx : 3
                            })
                        initCropper();
                        }}>► Stran</div>
                    <div className="btns__btn" onclick={() => {
                        findNextIndex()
                        /*if(AppState.findPic.currentPhotoIndex == 6){
                            AppState.findPic.page++;
                            Refresher.getAthletePictures({
                                idx : 0
                            })
                        }else{
                            AppState.findPic.currentPhotoIndex++;
                        }*/
                        initCropper();
                    }}>► Slika</div>
                </div>
            </div>
            <FindPicStep2PhotoCrop />

        </div>
    }
}

class FindPicStep2{

    onremove(){
        clearInterval(AppState.initCropperInterval)
        previous_img = '';
        cropper_instance.destroy();
        cropper_instance = null;
    }
    oninit(){
        Refresher.getAthletePictures()
        clearInterval(AppState.initCropperInterval);
        AppState.initCropperInterval = setInterval(() => {
            initCropper();
        }, 250)
    }
    view(){
        return <div class="step-content step-content--2">
            <div className="find-header">
                <div className="find-header__time">
                    <div className="find-header__time-label">Čas:</div>
                        <input type="text" value={ AppState.findPic.targetPhotoTime }
                               onkeyup={m.withAttr("value", AppState.findPic.setTargetPhotoTime)} />
                </div>
                (čas lahko uredite)
                {AppState.findPic.photosSearching && <span class="find-header__loading">Fotografije se iščejo</span>}
            </div>
            {AppState.findPic.photos.length &&
                <FindPicStep2PhotoList />}
        </div>
    }
}

function copyTextToClipboard() {
    /* Get the text field */
    var copyText = document.getElementById("to-copy");

    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /*For mobile devices*/

    /* Copy the text inside the text field */
    document.execCommand("copy");

    /* Alert the copied text */
    alert("Povezava je bila kopirana");
}

class FindPicStep3{
    view(){
        let link_share = '';
        if(AppState.env == 'prod'){
            link_share = 'https://siol.net/sportal/ljubljanski-maraton/fotoaparat-me-je-ujel-ko-sem-tekel-na-vw-24-ljubljanskem-maratonu-510026';
        }else if(AppState.env == 'dev'){
            link_share = 'http://127.0.0.1/test/trka-iframe/';
        }
        link_share += '?share=' + AppState.findPic.photoCreatingSharecode;
        let link_title = 'Tekel sem na maratonu'
        let link_desc = 'Poglejte me na Ljubljanskem maratonu 2019'

        let link_fb = 'https://www.facebook.com/sharer/sharer.php?u=' + link_share;
        let link_tw = 'https://twitter.com/home?status='+link_share;
        let link_tg = 'https://telegram.me/share/url?url='+link_share+'&text='+link_title;
        let link_em = 'mailto:friend@example.com?subject='+link_title+'&amp;body='+link_title+' '+link_share;

        return <div class="step-content step-content--3">
            <div className="message">Slika je bila ustvarjena. Svoj profil lahko delite tako, da kopirate spodnjo povezavo ali kliknete eno izmed spodnjih ikon.</div>
            <div className="link-location">
                <input type="text" id="to-copy" value={link_share} onclick={e => {
                    e.target.select()
                }} />
                <div class="btn" onclick={() => {
                    copyTextToClipboard()
                }}>Kopiraj povezavo</div>

                <br/>

                <div className="social_icons">
                    <a target="_blank" class="facebook" href={link_fb}></a>
                    <a target="_blank" class="twitter" href={link_tw}></a>
                    <a target="_blank" class="email" href={link_em}></a>
                    <a target="_blank" class="telegram" href={link_tg}></a>

                    {navigator.share && <div className="share" onclick={() => {
                        navigator.share({
                            title: link_title,
                            text: link_desc,
                            url: link_share,
                        }); // share the URL of MDN
                    }}>DELI</div> }
                </div>
            </div>

            <div className="links">
                <a class="links__link" href={link_share} target="_blank">Nadaljuj na moj tekaški profil</a>
            </div>

            <div className="share-img-preview">
                <img src={AppState.findPic.photoCreatingUrl} alt=""/>
            </div>

            <div className="links">
                <a class="links__link" onclick={() => {
                        AppState.findPic.step = 2;
                    }}>Nazaj na izbiro slike</a>
            </div>

        </div>
    }
}

export default class{
    view(){
        return <div class="page page--find-pic">
            <FindPicStepper />
            {AppState.findPic.step == 1 && <FindPicStep1 />}
            {AppState.findPic.step == 2 && <FindPicStep2 />}
            {AppState.findPic.step == 3 && <FindPicStep3 />}
        </div>
    }
};