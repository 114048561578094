const m = require("mithril");
import Refresher from "./utils/Refresher";
// import Dexie from 'dexie';
// const db = new Dexie('RefereeAssistDb');

var AppState = {
    currentPageRoot : '',
    loadingReady : {
        fonts: false
    },

    apiUrl : 'https://simple-tvs-web-twnus35pta-ew.a.run.app/',
    env : 'dev',

    isTiming : false,

    mXHRs : [],

    athletes : {
        refresh_date : 0,
        loading : false,
        list : []
    },

    pics : {
        refresh_date : 0,
        loading : false,
        list : []
    },

    filterTrasa : 42,
    filterK : 3, // int ali string "cilj"
    filterGender : 'b', // b, m or f
    sortOrder : 'abs_results', // latest
    filterAthlete : '',
    filterAthleteTimeout : null,
    setFilterAthlete : (v) => {
        AppState.filterAthlete = v
        clearTimeout(AppState.filterAthleteTimeout)
        AppState.filterAthleteTimeout = setTimeout(() => {
            Refresher.getAthletes()
        }, 300)
    },

    showAthlete : false,
    athleteRank : false,

    instance_id : '',

    stream : 'prenos',
    streamToken : '',
    streamList : {
        prenos : 'https://nimble-p.siolmaraton.si/ptv-abr/maratonprvi/playlist.m3u8',
        kongresni : 'https://nimble-p.siolmaraton.si/ptv-abr/maratondrugi/playlist.m3u8',
        vodilni : 'https://nimble-p.siolmaraton.si/ptv-abr/maratontretji/playlist.m3u8',
    },
    // streamListEmbeds : {
    //     prenos : { type : 'iframe-src', 'src' : 'https://player.vimeo.com/video/638019388?h=94115f9f86' },
    //     kongresni : { type : 'iframe-src', 'src' : 'https://livestream.com/accounts/10415057/events/8872050/videos/198911425/player?width=960&height=540&enableInfo=true&defaultDrawer=feed&autoPlay=false&mute=false' },
    //     vodilni : { type : 'iframe-src', 'src' : 'https://livestream.com/accounts/10415057/events/8872050/videos/198911425/player?width=960&height=540&enableInfo=true&defaultDrawer=feed&autoPlay=false&mute=false' },
    // },

    streamListEmbeds : {
        prenos : {
            type : 'm3u8',
            'src' : 'https://player.vimeo.com/play/6c728cba-856c-4bbf-9546-d6607b3b3f95/hls?s=763584512_1666758682_049681b340e69bf67b36a354b35b426c&context=Vimeo%5CController%5CApi%5CResources%5CVideoController.&oauth2_token_id=1049232188',
            poster: 'https://i.vimeocdn.com/video/1533501680-422e2ca98a9031e32e55a585cca47dfebd7236df7f2b6ce8ead145b8c46b1805-d_1280x720?r=pad'
        },
        // prenos : { type : 'm3u8', 'src' : 'https://smmx-pub.b-cdn.net/ljm/ljm_live_unavailable.jpg' },
    },
    // loadJwFile : 'https://nimble-p.siolmaraton.si/ptv-abr/maratonprvi/playlist.m3u8',

    gmapsObj : null,
    jwObj : null,
    jwObjInterval : null,

    photoSearch : false,

    trase : [
        {
            id : 10,
            name : '10km',
            k : [ // k1 = 5km
                [1, 5],
            ]
        },
        {
            id : 21,
            name : '21km',
            k : [ // k1 = 5km
                [1, 5],
                [2, 10],
                [3, 15],
                [4, 20]
            ]
        },
        {
            id : 42,
            name : '42km',
            k : [ // k1 = 5km
                [1, 5],
                [2, 10],
                [3, 15],
                [4, 20],
                [5, 25],
                [6, 30],
                [7, 35],
                [8, 40],
            ]
        },
    ],

    pageShareGet : {
        code : false,
        stnr : false,
        athlete_data : false,
        loading : false,

    },

    findPic : {
        // step : 3,
        // step : 2,
        step : 1,
        stnr : 22,
        showNotification: true,
        targetPhotoTime : '11:56:05',
        setTargetPhotoTime : function(time){
            AppState.findPic.targetPhotoTime = time;
            Refresher.getAthletePictures()
        },
        page : 0,
        searchTerm : '',
        setSearchTerm : function(term){
            AppState.findPic.searchTerm = term
            clearTimeout(AppState.findPic.searchTimeout)
            AppState.findPic.searchTimeout = setTimeout(function(){
                Refresher.getAthletesForPictures()
            }, 200)
        },
        athletesearch_status : 'none',
        searchTimeout : null,
        athletes : [],
        photos : [],
        photosSearching : false,
        currentPhotoIndex : 0,
        initCropperInterval : null,

        photoCreatingMessage : '',
        photoCreating : false,
        photoCreatingSharecode : '22-UZB',
        photoCreatingUrl : 'https://s3.eu-central-1.amazonaws.com/ljmaraton-2018/athletes/22_1540642069.jpg',

        x : false,
        y : false,
        w : false,
        h : false
    },

    map_kml_layers : {
        '42' : null,
        '21' : null,
        '10' : null
    },
    map_markers : {
        'izbran' : null,
        '10z' : null,
        '21z' : null,
        '42z' : null,
        '10m' : null,
        '42m' : null,
        '21m' : null,
        // 'foto' : null
    },

    gpsLocations : {
        "10m": false,
        "21m": false,
        "42m": false
    },

    fotoLocations : {
        '42' : [46.063714, 14.471598],
        '21' : [46.063714, 14.471598],
        '10' : [46.063715, 14.507894]
    },

    kmToLatLon : {
        '42' : {
            1 : [46.05764727748213, 14.50594799246925],
            2 : [46.06596180839372, 14.50924658811475],
            3 : [46.07515756123567, 14.51159184633116],
            4 : [46.08406371597179, 14.51363084523918],
            5 : [46.08993571730527, 14.50842537538861],
            6 : [46.08143861598117, 14.50715560685116],
            7 : [46.07253215307273, 14.50397520484225],
            8 : [46.06663825979088, 14.49868480326321],
            9 : [46.07279183099561, 14.49438685733721],
            10 : [46.08009438000000, 14.48681792000000],
            11 : [46.08690336000000, 14.47938105000000],
            12 : [46.08470156000000, 14.47247495000000],
            13 : [46.0758224000000, 14.47540285000000],
            14 : [46.07093189000000, 14.47837634000000],
            15 : [46.06417917000000, 14.4719593000000],
            16 : [46.05715809000000, 14.46167075000000],
            17 : [46.04885481000000, 14.46237307000000],
            18 : [46.04421884000000, 14.47184531000000],
            19 : [46.04608533000000, 14.48455019000000],
            20 : [46.04676507000000, 14.49291065000000],
            21 : [46.04311007000000, 14.49843581000000],
            22 : [46.03896304000000, 14.48749941000000],
            23 : [46.0371422000000, 14.49058897000000],
            24 : [46.03678654000000, 14.49653599000000],
            25 : [46.03678524000000, 14.50638415000000],
            26 : [46.02963129000000, 14.51622842000000],
            27 : [46.03041644000000, 14.52329447000000],
            28 : [46.03402672000000, 14.52282135000000],
            29 : [46.0367848000000, 14.51850899000000],
            30 : [46.04059768000000, 14.51893301000000],
            31 : [46.04476322000000, 14.52438662000000],
            32 : [46.04948186000000, 14.53706439000000],
            33 : [46.0546922000000, 14.53944763000000],
            34 : [46.06359803000000, 14.53489331000000],
            35 : [46.06500979000000, 14.52789487000000],
            36 : [46.07220468000000, 14.52716766000000],
            37 : [46.07371724000000, 14.51864902000000],
            38 : [46.06754798000000, 14.51400001000000],
            39 : [46.06240659000000, 14.51581082000000],
            40 : [46.05454402000000, 14.50528657000000],
            41 : [46.05333092000000, 14.508574000000],
        },
        '21' : {
            1 : [46.05764727748213, 14.50594799246925],
            2 : [46.06596180839372, 14.50924658811475],
            3 : [46.07515756123567, 14.51159184633116],
            4 : [46.08406371597179, 14.51363084523918],
            5 : [46.08993571730527, 14.50842537538861],
            6 : [46.08143861598117, 14.50715560685116],
            7 : [46.07253215307273, 14.50397520484225],
            8 : [46.06663825979088, 14.49868480326321],
            9 : [46.07279183099561, 14.49438685733721],
            10 : [46.08009438000000, 14.48681792000000],
            11 : [46.08690336000000, 14.47938105000000],
            12 : [46.08470156000000, 14.47247495000000],
            13 : [46.0758224000000, 14.47540285000000],
            14 : [46.07093189000000, 14.47837634000000],
            15 : [46.06417917000000, 14.4719593000000],
            16 : [46.05715809000000, 14.46167075000000],
            17 : [46.04885481000000, 14.46237307000000],
            18 : [46.04421884000000, 14.47184531000000],
            19 : [46.04608533000000, 14.48455019000000],
            20 : [46.04676507000000, 14.49291065000000],
        },
        '10' : {
            1 : [46.05764727748213, 14.50594799246925],
            2 : [46.06596180839372, 14.50924658811475],
            3 : [46.07515756123567, 14.51159184633116],
            4 : [46.08406371597179, 14.51363084523918],
            5 : [46.08993571730527, 14.50842537538861],
            6 : [46.08143861598117, 14.50715560685116],
            7 : [46.07253215307273, 14.50397520484225],
            8 : [46.0636446126143, 14.50398495778422],
            9 : [46.05764727748213, 14.50594799246925],
        }
    }
}


export default AppState;