import Refresher from "../utils/Refresher";
import {toHHMMSS, toMMSS} from '../utils/DateUtils'
const m = require("mithril");
import AppState from "../AppState";
import appState from "../AppState";
const timingLogo = require('../../img/timing-logo.png');

const timingBannerPrenos = require('../../img/timing-banner/prenos.png');
const timingBannerPtv = require('../../img/timing-banner/ptvlogo.png');
const timingBannerSiol = require('../../img/timing-banner/siollogo.png');
const timingBannerLjm = require('../../img/timing-banner/ljm.png');
const vwMaratonLogo = require('../../img/VW_maraton_banner_930x200.png');

class StreamChooser{
    view(){
        let items = [
            ['1', 'prenos', 'Prenos'],
            // ['2', 'kongresni', 'Kongresni trg'],
            // ['3', 'vodilni', 'Vodilni'],
            ['mapa', 'mapa', 'Mapa']
        ];
        return <div class="stream-chooser">
            {items.map(i => {
                let class_name_icon = 'stream__icon stream__icon--'+i[0]
                let class_name = 'stream' + (i[1] == AppState.stream ? ' stream--selected' : '');
                return <div className={class_name} onclick={() => {
                        AppState.stream = i[1];
                        if(i[0] != 'mapa'){
                            // console.log('jwplayer init 4 - ' + AppState.streamList[i[1]].replace('_T_', AppState.streamToken))
                            /*AppState.jwObj.load([{
                                "file": AppState.streamList[i[1]].replace('_T_', AppState.streamToken),
                                "image": ""
                            }])
                            AppState.jwObj.play();*/
                        }else{
                            Refresher.updateMapMarkers()
                            Refresher.getGpsPositions()
                            Refresher.getAthletes()
                        }
                    }}>
                    <div className={class_name_icon}></div>
                    <div className="stream__title">{i[2]}</div>
                </div>
            })}
        </div>;
    }
}

class LiveStream{
    oninit(){
        this.prevVideoStream = 'asdf';
        this.prevVideoStreamPlayer = 'asdf';
        this.currentEmbed = null;
    }
    view(vnode){
        let cls = 'embed-rec ' + (vnode.attrs.show ? '' : 'hidden');
        let streamToLoad = AppState.stream;
        let currentEmbed = null;

        if(streamToLoad != this.prevVideoStreamPlayer){
            console.log('updatestream')
            this.prevVideoStreamPlayer = streamToLoad;
        }

        if(streamToLoad != 'mapa'){

            return <div class={cls}>
                <iframe width="100%" height="420" src="https://video.siol.net/embed/CTEmajTdZi" frameBorder="0"
                        className="player-embed" scrolling="no" allowFullScreen></iframe>
            </div>

            if(streamToLoad != this.prevVideoStream){
                this.prevVideoStream = streamToLoad;
                console.log('change stream to ', streamToLoad)

                this.currentEmbed = AppState.streamListEmbeds[this.prevVideoStream];
                console.log(this.currentEmbed)
                if(this.currentEmbed.type == "m3u8"){
                    AppState.loadJwFile = this.currentEmbed.src;
                    if(appState.jwObj != null){
                        console.log('loading', this.currentEmbed.src)
                        AppState.jwObj.load([{
                                sources : [
                                    {
                                        "file": this.currentEmbed.src,
                                        "type" : "m3u8",
                                    }
                                ],
                                "image": this.currentEmbed.poster
                            }])
                            AppState.jwObj.play();
                    }
                }
            }

        }

        return <div></div>

        // return <div class={cls}>
        //     <div id="jwplayer-inst"></div>
        // </div>
        // return <div class={cls}>
        //     { currentEmbed && currentEmbed.type == "iframe-src" && <iframe src={currentEmbed.src} frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen ></iframe>}
        // </div>
        /*return <div class={cls}>
            <div id="jwplayer-inst">
                { currentEmbed && currentEmbed.type == "livestream" && <iframe src={currentEmbed.src}></iframe>}
                <pre>{ JSON.stringify() }</pre>
            </div>
        </div>*/
    }
}
class Map{
    view(vnode){
        let cls = 'stream-map ' + (vnode.attrs.show ? 'stream-map--show' : '');
        return <div class={cls}>
            <div id="gmap"></div>
        </div>
    }
}
class AthleteFilter{
    view(){
        return <div class="athlete-filter">
            <div class="side">
                <div className="toggle-filters">
                    <div class="filter">
                        <div class="filter__label">Trasa</div>
                        {AppState.trase.map(t => {
                            let itemClass = 'filter__item';
                            if(t.id == AppState.filterTrasa){
                                itemClass += ' filter__item--active';
                            }
                            return <div onclick={() => {
                                    AppState.filterTrasa = t.id;
                                    AppState.filterK = 1;


                                    Refresher.updateMapMarkers()
                                    Refresher.getGpsPositions()
                                    Refresher.getAthletes()
                                }}
                                class={itemClass}>{t.name}</div>
                        })}
                    </div>
                    <div class="filter">
                        <div class="filter__label">Točka</div>
                        {AppState.trase
                            .filter(t => t.id == AppState.filterTrasa)[0].k
                            .map(k => {
                                let itemClass = 'filter__item';
                                if (k[0] == AppState.filterK) {
                                    itemClass += ' filter__item--active';
                                }
                                return <div onclick={() => {
                                    AppState.filterK = k[0];
                                    Refresher.updateMapMarkers()
                                    Refresher.getAthletes()
                                }}
                                class={itemClass}>{k[1]}</div>
                            })}
                        <div onclick={() => {
                            AppState.filterK = 0;
                            Refresher.getAthletes()
                        }} className={
                            'filter__item'+(AppState.filterK == 0 ? ' filter__item--active' : '')
                        }>Cilj</div>
                    </div>
                    <div class="filter">
                        <div class="filter__label">Sortiraj</div>
                        {[['abs_results', 'Vrstni red'], ['rel_results', 'Zadnji prehodi']].map(s => {
                            let itemClass = 'filter__item';
                            if(s[0] == AppState.sortOrder){
                                itemClass += ' filter__item--active';
                            }
                            return <div onclick={() => {
                                    AppState.sortOrder = s[0];
                                Refresher.updateMapMarkers()
                                    Refresher.getAthletes()
                                }}
                                class={itemClass}>{s[1]}</div>
                        })}
                    </div>
                    <div class="filter">
                        <div class="filter__label">Spol</div>
                        {[['m', 'Moški'], ['f', 'Ženski'], ['b', 'Oba']].map(s => {
                            let itemClass = 'filter__item';
                            if(s[0] == AppState.filterGender){
                                itemClass += ' filter__item--active';
                            }
                            return <div onclick={() => {
                                    AppState.filterGender = s[0];
                                Refresher.updateMapMarkers()
                                    Refresher.getAthletes()
                                }}
                                class={itemClass}>{s[1]}</div>
                        })}
                    </div>
                </div>
            </div>
            <div class="side">
                <div class="search-box">
                    <input type="text"
                           // value={AppState.filterAthlete}
                           onkeyup={e => {
                               AppState.filterAthlete = e.target.value;
                               // console.log(e.target.value)
                               AppState.setFilterAthlete(AppState.filterAthlete)
                           }} placeholder="Poišči tekmovalca po priimku ali številki" />
                    <div class="search-box__clear"
                         onclick={() => { AppState.setFilterAthlete('') }}>
                        X
                    </div>
                </div>
            </div>
        </div>
    }
}

function showAthlete(a){
    AppState.athleteRank = false;
    AppState.showAthlete = a;
    Refresher.getAthleteRank(a.stnr);
}

class AthleteList{
    view(){
        let table_class = 'table table--stripped';
        table_class += AppState.athletes.loading ?
            ' table--loading' : '';
        return <div class="athlete-list">
            <div className="btn--refresh" onclick={() => {
                Refresher.getAthletes()
                Refresher.getGpsPositions()
            }}>
                Osveži seznam in lokacije na mapi
            </div>
            <table class={table_class}>
                <thead>
                    <tr>
                        <th width="60" class="text--right">Št. š</th>
                        <th width="">Tekmovalec</th>
                        <th width="70" class="text--center hide--m">Spol</th>
                        <th width="70" class="text--center">Država</th>
                        <th width="100" class="text--right">{AppState.filterK > 0 ? 'Vmesni čas' : 'Cilj'}</th>
                        <th width="100" class="text--right hide--s">Zaostanek</th>
                    </tr>
                </thead>
                <tbody>
                {AppState.athletes.list.map(a => {
                    let best_k_min_diff = AppState.filterK > 0
                        ? Math.abs(a.best_k_min - a['k'+AppState.filterK+'_ts'])
                        : Math.abs(a.best_k_min - a['netto_ts']);
                    let vmesnicas_cilj = AppState.filterK > 0
                        ? a['k'+AppState.filterK+'_ts']
                        : a.netto_ts;
                    return <tr>
                        <td class="text--right" onclick={ () => showAthlete(a)} data-t="Štartna Številka">{a.stnr}</td>
                        <td onclick={ () => showAthlete(a)}  data-t="Tekmovalec"><b>{a.priimek}</b> {a.ime}</td>
                        <td class="text--center hide--m" onclick={ () => showAthlete(a)}  data-t="Spol">{a.spol}</td>
                        <td class="text--center" onclick={ () => showAthlete(a)}  data-t="Država">{a.drzava}</td>
                        <td class="text--right" onclick={ () => showAthlete(a)}
                            data-t={AppState.filterK > 0 ? 'Vmesni čas' : 'Cilj'}>
                            {toHHMMSS(vmesnicas_cilj)}
                        </td>
                        <td class="text--right hide--s" onClick={() => showAthlete(a)} data-t="Zaostanek">
                            {best_k_min_diff > 0 ? '+'+toHHMMSS(best_k_min_diff) : ''}
                        </td>
                    </tr>
                })}
                </tbody>
            </table>
            <div className="notice-unofficial">
                Rezultati so informativni.
                Uradni rezultati:<br />
                {/*<a href="http://remote.timingljubljana.si/timing/Rezultati.aspx" target="_blank">*/}
                <a href="https://vw-ljubljanskimaraton.si/rezultati/maraton-polmaraton-rekreativni" target="_blank" rel="noopener norefereer">
                    <img src={timingLogo} alt=""/>
                </a>
            </div>
        </div>
    }
}

class AthleteProfile{
    view(){

        let athlete = AppState.showAthlete;

        let flag_src = athlete.drzava
            ? 'https://78884ca60822a34fb0e6-082b8fd5551e97bc65e327988b444396.ssl.cf3.rackcdn.com/flags/20x15/'+athlete.drzava.toLowerCase()+'.png'
            : '';

        return <div class="athlete-profile">
            <div class="athlete-profile__close" onclick={() => AppState.showAthlete = false}>
                <div className="label">Zapri</div>
            </div>
            <div className="athlete-profile__sides">
                <div className="side side--main_info">
                    <table className="table table--stripped">
                        <tr>
                            <td width="1">Kategorija</td>
                            <td>{athlete.cat}</td>
                        </tr>
                        <tr>
                            <td width="1">Priimek</td>
                            <td>{athlete.priimek}</td>
                        </tr>
                        <tr>
                            <td width="1">Ime</td>
                            <td>{athlete.ime}</td>
                        </tr>
                        <tr>
                            <td width="1">Spol</td>
                            <td>{athlete.spol}</td>
                        </tr>
                        {/*<tr>*/}
                            {/*<td width="1">Letnik rojstva</td>*/}
                            {/*<td>{athlete.lr}</td>*/}
                        {/*</tr>*/}
                        <tr>
                            <td width="1">Država</td>
                            <td><img src={flag_src} /></td>
                        </tr>
                        {AppState.athleteRank && <tr>
                            <td width="1">Rank</td>
                            <td>{AppState.athleteRank}</td>
                        </tr>}

                    </table>
                    {/*{! AppState.photoSearch && <a href="#!/find-pic" class="btn">*/}
                    {/*    Poišči svojo sliko*/}
                    {/*</a>}*/}

                </div>
                <div className="side">
                    <table class="table table--stripped">
                        <tr>
                            <td>Štart</td>
                            <td>{athlete.start}</td>
                            <td></td>
                            <td></td>
                        </tr>
                        {[1,2,3,4,5,6,7,8].map(ii => {
                            if(ii*5 > AppState.filterTrasa){
                                return;
                            }
                            let seconds = ii == 1
                                ? athlete.k1_ts
                                : athlete['k'+ii+'_ts'] - athlete['k'+(ii-1)+'_ts'];
                            let kmh = false;
                            let per_km = false;
                            if(seconds > 0){
                                kmh = (3600 / (seconds / 5)).toFixed(2);
                                per_km = Math.floor(seconds / 5)+"";
                            }
                            return <tr>
                                    <td>{ii * 5}km</td>
                                    <td>{toMMSS(seconds)}</td>
                                    {kmh ? <td>{kmh} km/h</td> : <td></td>}
                                    {per_km ? <td>{toMMSS(per_km)} / km</td> : <td></td>}
                                </tr>
                        })}
                        <tr>
                            <td>Cilj</td>
                            <td>{athlete.netto}</td>
                            <td></td>
                            <td></td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    }
}

export default class{
    oninit(){

        // Refresher.updateMapMarkers()
        Refresher.getGpsPositions()
        Refresher.getAthletes()

    }
    view(vnode){

        /*
        * <div class="embed-rec">
                <iframe allowFullScreen=""
                        scrolling="no" src="https://livestream.com/accounts/10415057/events/8431439/videos/182649260/player?width=640&amp;height=360&amp;enableInfo=true&amp;defaultDrawer=&amp;autoPlay=true&amp;mute=false"
                        frameBorder="0"></iframe>
            </div>
        * */
        return <div class="page page--live">
            {!AppState.isTiming && <LiveStream show={AppState.stream != 'mapa'} />}
            <Map show={AppState.stream == 'mapa'} />
            {!AppState.isTiming && <StreamChooser /> }
            {!AppState.isTiming && <img src={vwMaratonLogo} style="width:100%" /> }
            <div class="stream-athletes">
                <AthleteFilter />
                <AthleteList />
                {AppState.showAthlete != false && <AthleteProfile />}
            </div>
        </div>
    }
};